import React, { Component } from 'react';
import { ScrollView, View, RefreshControl } from 'react-native'
import { connect } from 'react-redux'
import { testevalActions } from '../../../actions'
import { CostumTabBottum, CostumTopbar, PendingTest, Loader } from '../_Common'
import moment from "moment";
import { Colors } from '../../../config'
class EvaluTestPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            attent: true,
            end: true,
            start: true
        }
        props.getTest()
        this._onRefresh = this._onRefresh.bind(this)
    }
    _handlehidden(stateName) {
        let currentState = this.state[stateName]
        this.setState({
            [stateName]: !currentState
        })
    }
    _onRefresh() {
        this.props.getTest()
    }
    render() {
        const { getingTestEval, dataTestEval } = this.props
        console.log(' 🚀  => dataTestEval:', dataTestEval)
        var start = null
        var attent = null
        var end = null
        if (getingTestEval === 1 && dataTestEval && dataTestEval.message && !Array.isArray(dataTestEval.message)) {
            start = Array.isArray(dataTestEval.message.start) ? dataTestEval.message.start : null
            attent = Array.isArray(dataTestEval.message.attent) ? dataTestEval.message.attent : null
            end = Array.isArray(dataTestEval.message.end) ? dataTestEval.message.end : null
        }
        return (
            <>
                <CostumTopbar title="Là, c’est le test !?" />
                {getingTestEval === 0 && <Loader />}
                <ScrollView
                    showsVerticalScrollIndicator={false}
                >
                    {start && <View style={this.state.start ? {} : { height: 0, overflow: "hidden" }}>
                        {start.map((test, key) => {
                            return  <>{test.test_connais && test.test_connais.evaluation_grid_id && test.test_connais.grid && (
                                <PendingTest key={key} typeTest="start" navigation={this.props.navigation} path="AnswerEvaluTest" idTest={test.jta_evaluation_id} time="" date={moment(new Date(test.date_evaluation.split(' ')[0])).startOf('day').fromNow()} navigation={this.props.navigation} />
                            )}</>
                        })}
                    </View>}
                    {attent && <View style={this.state.attent ? {} : { height: 0, overflow: "hidden" }}>
                        {attent.map((test, key) => {
                            return <>{test.test_connais && test.test_connais.evaluation_grid_id && test.test_connais.grid && (
                                 <PendingTest key={key} typeTest="attent" navigation={this.props.navigation} path="AnswerEvaluTest" idTest={test.jta_evaluation_id} time="" date={moment(new Date(test.date_evaluation.split(' ')[0])).startOf('day').fromNow()} navigation={this.props.navigation} />
                            )}</>
                        })}
                    </View>}
                    {end && <View style={this.state.end ? {} : { height: 0, overflow: "hidden" }}>
                        {end.map((test, key) => {
                            return  <>{test.test_connais && test.test_connais.evaluation_grid_id && test.test_connais.grid && (
                                <PendingTest key={key} typeTest="end" navigation={this.props.navigation} path="DetailEvaluTest" idTest={test.jta_evaluation_id} time="" date={moment(new Date(test.date_evaluation.split(' ')[0])).format('Do MMMM YYYY')} navigation={this.props.navigation} />
                            )}</>
                        })}
                    </View>}
                </ScrollView>
                <CostumTabBottum />
            </>
        )
    }
}
function mapState(state) {
    const { getingTestEval, dataTestEval } = state.testeval
    return { getingTestEval, dataTestEval }
}
const actionCreators = {
    getTest: testevalActions.getTest
};

const connectedClass = connect(mapState, actionCreators)(EvaluTestPage);
export { connectedClass as EvaluTestPage };