import React from 'react'
import { View, StyleSheet, Text } from 'react-native'
import { ArrowSvg } from './IconsSvg'
import { Colors } from '../../../config'
const BarState = (props) => {
    const types = {
        attent: {
            color: Colors.pending,
            bdgColor: Colors.pendingOpcaity,
            textBar: "Test en attent"
        },
        end: {
            color: Colors.succes,
            bdgColor: Colors.succesOpacity,
            textBar: "Test terminé"
        },
        start: {
            color: Colors.primary,
            bdgColor: "#2D70F340",
            textBar: "Test en cours"
        }
    }
    const styles = StyleSheet.create({
        container: {
            backgroundColor: types[props.typeBar].bdgColor,
            paddingVertical: 7,
            paddingHorizontal: 10,
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 10
        },
        textStyle: {
            color: types[props.typeBar].color,
            fontSize: 18,
            fontWeight: "700"
        }
    })
    return (
        <View style={styles.container}>
            <Text style={styles.textStyle}>{types[props.typeBar].textBar}</Text>
            <ArrowSvg color={types[props.typeBar].color} style={{ transition: .2, transform: [{ rotate: props.active ? "0deg" : "-90deg" }] }} />
        </View>
    )
}
export { BarState }