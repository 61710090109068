import { autotestConstants } from '../constants'
export function autotest(state, action) {
    switch (action.type) {
        case autotestConstants.GET_TEST_REQUEST:
            return {
                ...state,
                getingTestSelf: 0,
                dataTestSelf: action.payload
            }
        case autotestConstants.GET_TEST_SUCCESS:
            return {
                ...state,
                getingTestSelf: 1,
                dataTestSelf: action.payload
            }
        case autotestConstants.GET_TEST_FAILURE:
            return {
                ...state,
                getingTestSelf: 2,
                dataTestSelf: action.payload
            }
        case autotestConstants.GET_DETAIL_TEST_REQUEST:
            return {
                ...state,
                getingDetail: 0
            }
        case autotestConstants.GET_DETAIL_TEST_SUCCESS:
            let test = {
                test_id: "",
                labels: [],
                answers: []
            }
            if (action.payload && action.payload.tests) {
                if (action.payload.tests === "test not started") {
                    test.test_id = "waiting"
                } else if (action.payload.tests === "test deja submited") {
                    test.test_id = "submited"
                } else {
                    test.test_id = action.payload.tests.test_auto_id
                    let grid = action.payload.tests.autogrid
                    if (grid) {
                        test.labels = grid.label_auto_test ? grid.label_auto_test.split(';') : []
                        Array.isArray(grid.questions) && grid.questions.map(qst => {
                            test.answers.push({
                                detail_question_id: qst.question_auto_evaluation_id,
                                answer: null,
                                textQst: qst.textquestionautoeval,
                                needJustify: qst.need_justify,
                                justification: ""
                            })
                        })
                    }
                }

            }
            return {
                ...state,
                getingDetail: 1,
                detailSelfTest: test
            }
        case autotestConstants.GET_DETAIL_TEST_FAILURE:
            return {
                ...state,
                getingDetail: 2,
                detailSelfTest: action.payload
            }
        case autotestConstants.SUBMIT_TEST_REQUEST:
            return {
                ...state,
                submitingTest: 0,
                dataSubmit: action.payload
            }
        case autotestConstants.SUBMIT_TEST_SUCCESS:
            let resullt = {
                dataSubmit: action.payload
            }
            if (action.payload && action.payload.message === "sucessfully") {
                resullt = {
                    detailSelfTest: {
                        test_id: "submited",
                        labels: [],
                        answers: []
                    }
                }
            }
            return {
                ...state,
                submitingTest: 1,
                ...resullt
            }
        case autotestConstants.SUBMIT_TEST_FAILURE:
            return {
                ...state,
                submitingTest: 2,
                dataSubmit: action.payload
            }
        default:
            return {
                detailSelfTest: {
                    test_id: "",
                    labels: [],
                    answers: []
                },
                ...state
            }
    }
}