import React, { Component } from 'react'
import {
    View,
    StyleSheet,
    Text,
    TouchableOpacity,
    Linking
} from 'react-native';
import Clipboard from '@react-native-clipboard/clipboard';
//import { RNCamera } from 'react-native-camera';
import { Colors } from '../../../config'
import { CopySvg, SubmitBtn, RetrySvg,CostumTopbar } from '../_Common'
import {Html5QrcodeScannerPlugin} from './QrScannerPlugin'
class QrCodeScanner extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataQrCode: null
        }
        this.handleRetry = this.handleRetry.bind(this)
        this.handleScan=this.handleScan.bind(this)
    }
    handleLinkOpen = link => {
        Linking.openURL(link).catch(err =>
            console.error('An error occured', err)
        );
    };
    handleRetry() {
        this.setState({
            dataQrCode: null
        })
    }
    copyString(Text) {
        Clipboard.setString(Text);
    }
    handleScan = data => {
        if(data && !this.state.dataQrCode){
            this.setState({
                dataQrCode:data
            })
        }
        
    }
    render() {
        return (
            <>
            <CostumTopbar title="Scanner un QR code" goBack={()=>this.props.history.push("/More")}/>
            <View style={Styles.flexElems}>
                <Text style={Styles.title}>Mettre la caméra sur le QR code</Text>
                <View style={Styles.cameraContainer}>
                    {this.state.dataQrCode ? <TryComponent onRetry={this.handleRetry} />:<Html5QrcodeScannerPlugin
                    fps={10}
                    qrBox={200}
                    disableFlip={false}
                    qrCodeSuccessCallback={this.handleScan}
                />}
                </View>
                {this.state.dataQrCode && <>
                    <View style={Styles.LinkData}>
                        <View style={{ flex: 1, marginRight: 8 }}>
                            <Text style={{ fontWeight: "500", fontSize: 18 }} numberOfLines={2}>{this.state.dataQrCode}</Text>
                        </View>
                        <TouchableOpacity onPress={() => this.copyString(this.state.dataQrCode)}>
                            <CopySvg width={30} height={30} color={Colors.primary} />
                        </TouchableOpacity>
                    </View>
                    <View style={{ marginHorizontal: "10%" }}>
                        <SubmitBtn actionPress={() => this.handleLinkOpen(this.state.dataQrCode)} text="Ovrire le lien" bdgClr={Colors.primaryOpcaity} fontClr={Colors.primary} />
                    </View>
                </>}
            </View>
            </>
        )
    }
}
export { QrCodeScanner }
const TryComponent = (props) => {
    return (
        <View style={Styles.contentTrying}>
            <TouchableOpacity onPress={props.onRetry}>
                <View style={Styles.retryStyle}>
                    <RetrySvg color="#fff" width={150} height={150} />
                </View>
            </TouchableOpacity>

        </View>
    )
}
const Styles = StyleSheet.create({
    contentTrying: {
        width: "100%",
        height: "100%",
        zIndex:100,
        position:"absolute",
        backgroundColor: "#00000080",
        justifyContent: 'center',
        alignItems: 'center',
        shadowColor: "#fff",
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: 0.7,
        shadowRadius: 10,
        elevation: 200,
    },
    flexElems: {
        flexDirection: "column",
        width: "100%",
        height: "100%"
    },
    cameraContainer: {
        height: 350,
        borderRadius: 5,
        marginHorizontal: 10,
        marginVertical: 10,
        overflow: "hidden",
        shadowColor: "#000000",
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: 0.7,
        shadowRadius: 10,
        elevation: 20,
    },
    title: {
        fontWeight: "700",
        textAlign: "center",
        fontSize: 19,
        marginVertical: 10
    },
    LinkData: {
        marginTop: 10,
        paddingVertical: 10,
        paddingRight: 6,
        paddingLeft: 12,
        marginHorizontal: "4%",
        width: "92%",
        borderRadius: 3,
        backgroundColor: "#fff",
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: "space-between",
        shadowColor: "#00000020",
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: 0.4,
        shadowRadius: 5,
        elevation: 8,
    }
})