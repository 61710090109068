import React from 'react'
import { BackSvg } from './IconsSvg'
import { View, StyleSheet, TouchableOpacity, Text } from 'react-native'
import { Colors } from '../../../config'
const styles = StyleSheet.create({
    container: {
        width: "100%",
        height: 55,
        paddingHorizontal: 15,
        backgroundColor: Colors.primary,
        boxShadow: '0px 0px 10px #9c9c9c'
    },
    flexView: {
        height: '100%',
        flexDirection: 'row',
        justifyContent: "flex-start",
        alignItems: 'center'
    },
    titleStyle: {
        fontSize: 20,
        fontWeight: 600,
        color: '#ffffff'
    }
});
const CostumTopbar = (props) => {
    return (
        <View style={styles.container}>
            <View style={styles.flexView}>
                {props.goBack &&
                    <TouchableOpacity onPress={props.goBack}>
                        <View style={{ marginRight: 15, flexDirection: "row", alignItems: "center" }}>
                            <BackSvg width="25" height="25" />
                        </View>
                    </TouchableOpacity>
                }
                <Text style={styles.titleStyle}>{props.title}</Text>
            </View>
        </View>
    )
}
export { CostumTopbar }