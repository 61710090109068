import { userConstants } from '../constants';
import { RequestService,FunctionService } from '../services';
export const authActions = {
    Login,
    UpdatePassword,
    getUser,
    uploadPicture,
    getTokenPusher
};
function Login(user){
    return dispatch => {
        dispatch(FunctionService.request(userConstants.LOGIN_REQUEST))
        RequestService.PostRequest('user/login',user)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,userConstants.LOGIN_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,userConstants.LOGIN_FAILURE))
        })
    }
}
function uploadPicture(data,token){
    return dispatch => {
        dispatch(FunctionService.request(userConstants.UPLOADING_PIC_REQUEST))
        RequestService.PostRequest('user/picture',data,true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,userConstants.UPLOADING_PIC_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,userConstants.UPLOADING_PIC_FAILURE))
        })
    }
}
function getUser(){
    return dispatch => {
        dispatch(FunctionService.request(userConstants.GET_USER_REQUEST))
        RequestService.GetRequest('user',true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,userConstants.GET_USER_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,userConstants.GET_USER_FAILURE))
        })
    }
}
function UpdatePassword(dataPassword,token){
    return dispatch => {
        dispatch(FunctionService.request(userConstants.UPDATE_PASSWORD_REQUEST))
        RequestService.PostRequest('password',dataPassword,true,{"Authorization":"Bearer "+token})
        .then(res=>{
            dispatch(FunctionService.succes(res.data,userConstants.UPDATE_PASSWORD_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,userConstants.UPDATE_PASSWORD_FAILURE))
        })
    }
}
function getTokenPusher(token){
    return dispatch => {
        dispatch(FunctionService.request(userConstants.GET_TOKEN_PUSH_REQUEST))
        RequestService.GetRequest('pusher/beams-auth',true,{"Authorization":"Bearer "+token})
        .then(res=>{
            dispatch(FunctionService.succes(res.data,userConstants.GET_TOKEN_PUSH_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,userConstants.GET_TOKEN_PUSH_FAILURE))
        })
    }
}