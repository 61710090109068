import { messagingConstants } from "../constants";

export function messaging(state, action) {
    switch (action.type) {
        case messagingConstants.SYNC_MESSAGES_CHANNEL_REQESUT:
            return {
                ...state,
                syncMessageChannel: 0
            }
        case messagingConstants.SYNC_MESSAGES_CHANNEL_SUCCESS:
            const { messages = [] } = action.payload
            return {
                ...state,
                syncMessageChannel: 1,
                messagesChannel: messages
            }
        case messagingConstants.SYNC_MESSAGES_CHANNEL_FAILEUR:
            return {
                ...state,
                syncMessageChannel: 2
            }
        /******************* */

        case messagingConstants.SET_MESSAGES_CHANNEL:
            const { message = {} } = action.payload
            return {
                ...state,
                messagesChannel: [...state.messagesChannel, message]
            }

        default:
            return {
                messagesChannel: [],
                ...state
            }
    }
}