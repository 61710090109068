export const userConstants = {
    LOGIN_SUCCESS: 'LOGIN_USER_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_USER_FAILURE',
    LOGIN_REQUEST: 'LOGIN_USER_REQUEST',

    UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_USER_SUCCESS',
    UPDATE_PASSWORD_FAILURE: 'UPDATE_PASSWORD_USER_FAILURE',
    UPDATE_PASSWORD_REQUEST: 'UPDATE_PASSWORD_USER_REQUEST',

    GET_USER_SUCCESS: 'GET_USER_SUCCESS',
    GET_USER_FAILURE: 'GET_USER_FAILURE',
    GET_USER_REQUEST: 'GET_USER_REQUEST',

    UPLOADING_PIC_SUCCESS: 'UPLOADING_PIC_SUCCESS',
    UPLOADING_PIC_FAILURE: 'UPLOADING_PIC_FAILURE',
    UPLOADING_PIC_REQUEST: 'UPLOADING_PIC_REQUEST',

    GET_TOKEN_PUSH_SUCCESS: 'GET_TOKEN_PUSH_SUCCESS',
    GET_TOKEN_PUSH_FAILURE: 'GET_TOKEN_PUSH_FAILURE',
    GET_TOKEN_PUSH_REQUEST: 'GET_TOKEN_PUSH_REQUEST',
};