import { StyleSheet } from 'react-native'
import { Colors } from '../../../../config'
export const styles = StyleSheet.create({
    inputMessage: {
        paddingVertical: 13,
        paddingHorizontal: 10,
        width: "100%"
    },
    sendMessage: {
        display: 'flex',
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingRight: 10,
        backgroundColor: Colors.white
    },
    message: {
        marginBottom: 10,
        display: 'flex'
    },
    messageContent: {
        paddingHorizontal: 10,
        paddingVertical: 8,
        borderRadius: 7,
        width: "max-content",
        maxWidth: "75%"
    },
    textStyle: {
        fontSize: 16,
        lineHeight: 24
    }
})