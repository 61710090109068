import React, { Component } from "react";
import { ScrollView, Text, TextInput, TouchableHighlight, View } from "react-native";
import { connect } from "react-redux";
import { messagingActions } from "../../../actions";
import { Colors } from "../../../config";
import { CostumTopbar, Loader, SendMessageSvg } from "../_Common";
import { MessageItem, styles } from "./utils";

class Messaging extends Component {
    state = {
        message: ""
    }
    scroleBottom = false
    refMessage = React.createRef()
    componentDidMount() {
        this.fetchMessages()
        window.Echo.channel(`messaging-${this.props.dataUser.user.socket_channel}`)
            .listen('MessagingEvent', (e) => {
                if (e.message && e.source) {
                    this.scroleBottom = true
                    this.props.AddMessage(e.message)
                }
            })
    }
    componentDidUpdate(prevProps) {
        if (prevProps.syncMessageChannel === 0 && this.props.syncMessageChannel === 1) {
            this.scrollToBottom()
        }
        if (this.scroleBottom) {
            this.scrollToBottom()
            this.scroleBottom = false
        }
    }
    fetchMessages = () => {
        this.props.GetMessages()
    }
    setMessageState = (text) => {
        this.setState({
            message: text
        })
    }
    submitMessage = () => {
        if (!this.state.message) return
        this.props.SendMessage({ message: this.state.message })
        this.state.message = ""
    }
    scrollToBottom = () => {
        if (this.refMessage && this.refMessage.current) {
            this.refMessage.current.scrollIntoView({ behavior: 'smooth' })
        }
    }
    render() {
        const { syncMessageChannel, messagesChannel } = this.props
        return (
            <>
                <CostumTopbar title="Boîte à questions" goBack={() => this.props.history.push("/More")} />
                {syncMessageChannel === 0 && <Loader />}
                <ScrollView showsVerticalScrollIndicator={false} style={{ paddingHorizontal: 7, paddingVertical: 15 }}>
                    {messagesChannel.map(({ message_text, is_me } = {}, key) => {
                        return <MessageItem key={key} message={message_text} isMe={is_me} />
                    })}
                    <View ref={this.refMessage}></View>
                </ScrollView>
                <View style={styles.sendMessage}>
                    <TextInput
                        onChangeText={this.setMessageState}
                        value={this.state.message}
                        style={styles.inputMessage}
                        placeholder="Écrire votre question ..."
                    />
                    <TouchableHighlight onPress={this.submitMessage}>
                        <SendMessageSvg width={25} height={25} color={Colors.primary} />
                    </TouchableHighlight>
                </View>

            </>
        )
    }
}
function mapState(state) {
    const { syncMessageChannel, messagesChannel } = state.messaging
    const { dataUser } = state.auth
    return { syncMessageChannel, messagesChannel, dataUser }
}
const connectedClass = connect(mapState, messagingActions)(Messaging);
export { connectedClass as Messaging };