import React from 'react'
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import { PlaySvg } from './IconsSvg'
import { Colors } from '../../../config'
export function PendingTest(props) {
    const types = {
        attent: {
            color: Colors.pending,
            bdgColor: Colors.pendingOpcaity,
            textBar: "En attente"
        },
        end: {
            color: Colors.succes,
            bdgColor: Colors.succesOpacity,
            textBar: "Terminé"
        },
        start: {
            color: Colors.primary,
            bdgColor: "#2D70F340",
            textBar: "En cours"
        }
    }
    const styles = StyleSheet.create({
        container: {
            width: "95%",
            backgroundColor: "#FFFFFF",
            marginStart: "auto",
            marginEnd: "auto",
            marginTop: 15,
            paddingHorizontal: 13,
            paddingVertical: 10,
            flexDirection: 'column',
            justifyContent: 'space-between',
            borderRadius: 15
        },
        alignRw: {
            flexDirection: 'row',
            alignItems: "center",
            justifyContent: 'space-between'
        },
        Title: {
            fontWeight: "700",
            fontSize: 19,
            color: "#252423",
            textTransform: "capitalize"
        },
        semiTitle: {
            fontWeight: "500",
            fontSize: 13,
            color: "#252423"
        },
        Flex: {
            flexDirection: 'row',
            alignItems: "center"
        }
    });
    return (
        <TouchableOpacity onPress={() => props.navigation.navigate(props.path, { idtest: props.idTest })}>
            <View style={styles.container}>
                <View style={styles.alignRw}>
                    <View style={styles.Flex}>
                        <Text style={styles.Title}>{props.date}</Text>
                        <Text style={{ ...styles.semiTitle, marginLeft: 5 }}>{props.time}</Text>
                    </View>
                    <Text style={styles.semiTitle}>{props.type === "1" ? "Test d'auto-évaluation" : "Test de conaissance"}</Text>
                </View>
                <View style={[styles.alignRw,{marginTop:20}]}>
                    <View style={{ paddingHorizontal: 13, paddingVertical: 5, borderRadius: 14, backgroundColor: types[props.typeTest].bdgColor }}>
                        <Text style={{ color: types[props.typeTest].color }}>{types[props.typeTest].textBar}</Text>
                    </View>
                    <Text><PlaySvg /></Text>
                </View>
            </View>
        </TouchableOpacity>
    )
}