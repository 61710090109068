import { messagingConstants } from '../constants';
import { FunctionService, RequestService } from '../services';
export const messagingActions = {
    GetMessages,
    SendMessage,
    AddMessage
}
function GetMessages() {
    return dispatch => {
        dispatch(FunctionService.request(messagingConstants.SYNC_MESSAGES_CHANNEL_REQESUT))
        RequestService.GetRequest(`messages/me`, true)
            .then(({ data = {} }) => {
                dispatch(FunctionService.succes(data, messagingConstants.SYNC_MESSAGES_CHANNEL_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err, messagingConstants.SYNC_MESSAGES_CHANNEL_FAILEUR))
            })
    }
}
function SendMessage(message) {
    return dispatch => {
        dispatch(FunctionService.request(messagingConstants.SYNC_MESSAGES_CHANNEL_REQESUT))
        RequestService.PostRequest(`messages/send`, message, true)
            .then(({ data = {} }) => {
                dispatch(FunctionService.succes(data, messagingConstants.SYNC_MESSAGES_CHANNEL_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err, messagingConstants.SYNC_MESSAGES_CHANNEL_FAILEUR))
            })
    }
}
function AddMessage(message) {
    return dispatch => {
        dispatch(FunctionService.succes({ message }, messagingConstants.SET_MESSAGES_CHANNEL))
    }
}