import React from 'react'
const Html5Qrcode=window.Html5Qrcode
class Html5QrcodeScannerPlugin extends React.Component {
    constructor(props){
        super(props)
        this.html5QrCode=null
        this.devices=[]
    }
    componentDidMount() {
        this.html5QrCode = new Html5Qrcode("qr-code-full-region");
        Html5Qrcode.getCameras().then(devices => {
            if (devices && devices.length) {
                this.devices = devices
                let cameraSelected = devices[1] ? 1 : devices[0] ? 0 : null
                if (cameraSelected !== null) {
                    this.lancheScan(cameraSelected)
                }
            }
        }).catch(err => {}
        
        );
    }
    componentWillUnmount(){
        if(this.html5QrCode){
            this.html5QrCode.stop()
        }
    }
    lancheScan(index){
        this.html5QrCode.start(this.devices[index].id, {
            fps: 30,
            qrbox: 210
        },
        qrCodeMessage => {
            this.props.qrCodeSuccessCallback(qrCodeMessage)
        },
        errorMessage => {}

    ).catch(err => {}

    );
    }
    render() {
        return <div style={{height:"100%",width:"100%"}} id={'qr-code-full-region'} />;
    }
}
export {Html5QrcodeScannerPlugin}