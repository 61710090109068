import React, { Component } from 'react'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'
import { Colors } from '../../../config'
const styles = StyleSheet.create({
    container: {
        flexDirection: "column",
        alignItems: "center",
        paddingVertical: 15,
        paddingHorizontal: 20,
        width: "90%",
        marginLeft: "5%",
        borderRadius: 20,
        marginVertical: 7,
    },
    titleText: {
        fontSize: 20,
        fontWeight: "500",
        textAlign: "center"
    },
    checkedValue: {
        backgroundColor: "#2D70F310",
        borderRadius: 20,
        borderWidth: 2,
        borderColor: Colors.primary
    },
    default: {
        backgroundColor: "#f0f2f5"
    },
    titleCheck: {
        color: Colors.primary
    },
    titleDefault: {
        color: "#252423"
    }
})
export const ElementQestion = (props) => {
    return (
        props.labelsList.map((label, key) => {
            return <TouchableOpacity key={key} onPress={() => props.handleSelect(label)}>
                <View style={{ ...styles.container, ...(props.answer === label ? styles.checkedValue : styles.default) }}>
                    <Text style={{ ...styles.titleText, ...(props.answer === label ? styles.titleCheck : styles.titleDefault) }}>{label}</Text>
                </View>
            </TouchableOpacity>
        })

    )
}