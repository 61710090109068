import React, { Component } from 'react';
import { StyleSheet, View, Text, ScrollView } from 'react-native'
import { connect } from 'react-redux'
import { authActions } from '../../actions'
import { Login, UpdatePassword } from './component'
import { Loader, LindustreetIcon } from '../AppPages/_Common'
import { Colors } from '../../config'
import Cookies from 'js-cookie'
const styles = StyleSheet.create({
    container: {
        height: "100%",
        flexDirection: "column",
        justifyContent: "center"
    },
    login: {
        width: "94%",
        height: "100%",
        marginHorizontal: "3%",
        marginVertical: 20,
        height: 400,
        backgroundColor: "#fff",
        borderRadius: 15,
        paddingHorizontal: 12,
        paddingVertical: 20,
        marginTop: 20
    },
    headerLogo: {
        paddingVertical: 20,
        backgroundColor: '#FFF',
        paddingHorizontal: 10,
        flexDirection: "row",
        alignItems: "center",
        shadowColor: "#000000",
        shadowOffset: {
            width: 5,
            height: 3,
        },
        shadowOpacity: 0.2,
        shadowRadius: 5,
        elevation: 5,
    },
    TextHeader: {
        fontWeight: "700",
        fontSize: 30,
        marginLeft: 10
    }
})
class LoginPage extends Component {
    constructor(props) {
        super(props)
        this.user = {
            email: "",
            password: ""
        }
        this.password = {
            password: "",
            confirm: ""
        }
        this._handleChange = this._handleChange.bind(this)
        this._handleSubmit = this._handleSubmit.bind(this)
        this._handlePassword = this._handlePassword.bind(this)

    }
    setAuth(token) {
        Cookies.set('c_user', token)
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { loging, dataLogin, getingToken, dataTokenPush } = nextProps
        if (this.props.loging === 0 && loging === 1 && dataLogin && dataLogin.typeuser === "cand" && dataLogin.token) {
            this.setAuth(dataLogin.token)
            window.location.reload()
        }
    }

    _handleChange(elem) {
        if (elem.varuse === "user") {
            this.user[elem.type] = elem.value
        }
        if (elem.varuse === "password") {
            this.password[elem.type] = elem.value
        }
    }
    _handleSubmit() {
        this.props.Login(this.user)
    }
    _handlePassword() {
        this.props.UpdatePassword(this.password, this.props.dataLogin.token)
    }
    render() {
        const { loging, dataLogin, updatpass, uploading } = this.props
        var update = false
        return (
            <View style={styles.container}>
                <View style={styles.headerLogo}>
                    <LindustreetIcon color={Colors.primary} fillGlobal={Colors.primary} fillSub="#FFF" width={70} height={70} />
                    <Text style={styles.TextHeader}>L'INDUSTREET</Text>
                </View>
                <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={{ marginTop: 25 }}>
                    {(loging === 0 || updatpass === 0 || uploading === 0) && <Loader />}
                    <View style={styles.login}>
                        {!update ? <Login
                            _handleChange={this._handleChange}
                            _handleSubmit={this._handleSubmit}
                            error={loging === 2}
                        /> : <UpdatePassword
                            _handleChange={this._handleChange}
                            _handleAccept={this._handleAccept}
                            _handleSubmit={this._handlePassword}
                        />
                        }
                    </View>
                </ScrollView>
            </View>
        )
    }
}
function mapState(state) {
    const { loging, dataLogin, updatpass, dataPass, getingToken, dataTokenPush } = state.auth
    return { loging, dataLogin, updatpass, dataPass, getingToken, dataTokenPush }
}
const actionCreators = {
    Login: authActions.Login,
    UpdatePassword: authActions.UpdatePassword,
    getTokenPusher: authActions.getTokenPusher
};

const connectedClass = connect(mapState, actionCreators)(LoginPage);
export { connectedClass as LoginPage };