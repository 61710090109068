import React, { Component } from 'react';
import { Text, View } from 'react-native'
import { connect } from 'react-redux'
import { testevalActions } from '../../../actions'
import { Loader, CheckElement, CheckIcon, WarningSvg,CostumTopbar } from '../_Common'
class DetailEvaluTestPage extends Component {
    constructor(props) {
        super(props)
        props.getDetailTest(props.match.params.idtest)
    }
    render() {
        const { getingDetailEval, dataDetailEval } = this.props
        let note = ""
        if (dataDetailEval && typeof dataDetailEval.message === "string") {
            note = dataDetailEval.message
        }
        console.log('note = dataDetailEval.message : ',dataDetailEval)
        return (
            <>
            <CostumTopbar title="Detail de test" goBack={this.props.history.goBack} />
            <View style={{ flex: 1, height: "100%" }}>
                {getingDetailEval === 0 ? <Loader /> :
                    note === "test missed" ?
                        <CheckElement Icon={<WarningSvg width="110" height="110" />}>
                            <Text style={{ fontSize: 20, fontWeight: "700", textAlign: "center" }}>Nous sommes déçus de ne pas vous avoir vu dans le test ? 😌</Text>
                        </CheckElement>
                        :
                        <CheckElement Icon={<CheckIcon width="110" height="110" />}>
                            <Text style={{ fontSize: 20, fontWeight: "700", textAlign: "center" }}>Votre test de connaissances est terminée ! Bravo ! ☺️</Text>
                        </CheckElement>
                }</View>
            </>
        )
    }
}
function mapState(state) {
    const { getingDetailEval, dataDetailEval } = state.testeval
    return { getingDetailEval, dataDetailEval }
}
const actionCreators = {
    getDetailTest: testevalActions.getDetailTest
};

const connectedClass = connect(mapState, actionCreators)(DetailEvaluTestPage);
export { connectedClass as DetailEvaluTestPage };