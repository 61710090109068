import React, { Component } from 'react';
import { Text, ScrollView, View, StyleSheet, TextInput } from 'react-native'
import { connect } from 'react-redux'
import { autotestActions } from '../../../actions'
import { ElementQestion } from './ElementQestion'
import { Loader, SubmitBtn, CheckElement, CheckIcon, WaitingSvg ,CostumTopbar} from '../_Common'
import { Colors } from '../../../config'
class AnswerAutoTest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            answers: [],
            idTest: null,
            crntQestion: 0
        }
        this.lengthQestion = 0
        this.handleAnswer = this.handleAnswer.bind(this)
        this.handlePosition = this.handlePosition.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        props.getDetailTest(props.match.params.idtest)
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { getingDetail, detailSelfTest } = nextProps
        if (this.props.getingDetail === 0 && getingDetail === 1 && detailSelfTest.test_id) {
            this.state.answers = detailSelfTest.answers
            this.state.idTest = detailSelfTest.test_id
            this.lengthQestion = detailSelfTest.answers.length
        }
    }
    handleAnswer(value) {
        let answers = this.state.answers
        answers[this.state.crntQestion].answer = value
        this.setState({
            answers: answers
        })
    }
    handlePosition(value) {
        if (value === 1 && (this.state.answers[this.state.crntQestion].answer === null || (this.state.answers[this.state.crntQestion].needJustify && !this.state.answers[this.state.crntQestion].justification))) {
            return 0
        }
        this.setState({
            crntQestion: this.state.crntQestion + value
        })
    }
    handleSubmit() {
        if (this.state.answers[this.state.crntQestion].answer === null || (this.state.answers[this.state.crntQestion].needJustify && !this.state.answers[this.state.crntQestion].justification)) {
            return 0
        }
        let submit = {
            test_auto_id: this.state.idTest,
            answers: this.state.answers
        }
        this.props.submitAnswer(submit)
    }
    _handleAnswer(text) {
        let answers = this.state.answers
        answers[this.state.crntQestion].justification = text
        this.setState({
            answers: answers
        })
    }
    render() {
        const { getingDetail, detailSelfTest, submitingTest } = this.props
        let textQestion = "", answer = "", needJustify = false, justification = ""
        if (this.state.answers[this.state.crntQestion]) {
            textQestion = this.state.answers[this.state.crntQestion].textQst
            answer = this.state.answers[this.state.crntQestion].answer
            needJustify = this.state.answers[this.state.crntQestion].needJustify
            justification = this.state.answers[this.state.crntQestion].justification
        }
        return (
            <>
            <CostumTopbar title="Auto évaluation" goBack={this.props.history.goBack} />
                {submitingTest === 0 && <Loader />}
                {getingDetail === 0 ? <Loader /> :
                    <View style={{ height: "100%", padding: 15, backgroundColor: "#FFFFFF" }}>
                        {detailSelfTest.test_id === "waiting" ?
                            <CheckElement Icon={<WaitingSvg width="110" height="110" />}>
                                <Text style={{ fontSize: 20, fontWeight: "700", textAlign: "center" }}>Vous recevrez une notification de la part de L’Industreet lorsque le test commencera ☺️</Text>
                            </CheckElement> : detailSelfTest.test_id === "submited" ?
                                <CheckElement Icon={<CheckIcon width="110" height="110" />}>
                                    <Text style={{ fontSize: 20, fontWeight: "700", textAlign: "center" }}>Votre évaluation est terminée ! Bravo ! 😀</Text>
                                </CheckElement> : <>
                                    <View>
                                        <View style={styles.topTextView}>
                                            <Text style={styles.textTop}>Question {this.state.crntQestion + 1} /</Text>
                                            <Text style={{ fontSize: 17, color: "#8d949e" }}>{this.lengthQestion}</Text>
                                        </View>
                                    </View>
                                    <Text style={styles.textAnswer}>{textQestion}</Text>
                                    <ScrollView
                                        showsVerticalScrollIndicator={false}
                                    >
                                        <ElementQestion answer={answer} labelsList={detailSelfTest.labels} handleSelect={this.handleAnswer} />
                                        {needJustify && <View>
                                            <TextInput
                                                onChangeText={text => this._handleAnswer(text)}
                                                value={justification}
                                                style={{ borderColor: "#2D70F370", fontSize: 16, marginTop: 10, paddingHorizontal: 10, borderWidth: 2, borderRadius: 8, height: 100, textAlignVertical: 'top' }}
                                                placeholder="Justifier votre réponse..."
                                                multiline={true}
                                                numberOfLines={10}
                                            />
                                        </View>}
                                        <View style={styles.flexBtn}>
                                            {(this.state.crntQestion > 0 && this.lengthQestion > 1) && <SubmitBtn actionPress={() => this.handlePosition(-1)} text="Précedent" bdgClr="#2D70F320" fontClr={Colors.primary} />}
                                            <SubmitBtn actionPress={(this.state.crntQestion === (this.lengthQestion - 1)) ? this.handleSubmit : () => this.handlePosition(1)} text={(this.state.crntQestion === (this.lengthQestion - 1)) ? "Envoyer" : "Suivant"} bdgClr={Colors.primary} fontClr="#FFFFFF" />
                                        </View>
                                    </ScrollView></>}
                    </View>}
            </>
        )
    }
}
const styles = StyleSheet.create({
    textTop: {
        fontWeight: "700",
        fontSize: 25,
        color: "#8d949e"
    },
    topTextView: {
        borderBottomWidth: 1,
        paddingVertical: 10,
        marginBottom: 20,
        paddingHorizontal: 20,
        borderBottomColor: "#00000020",
        flexDirection: "row",
        alignItems: "flex-end"
    },
    textAnswer: {
        fontSize: 20,
        color: "#252423",
        fontWeight: "700",
        marginBottom: 20
    },
    flexBtn: {
        flexDirection: "row",
        justifyContent: "space-around"
    },
    columFlex: {
        flexDirection: "column",
        justifyContent: "space-between"
    }
})
function mapState(state) {
    const { getingDetail, detailSelfTest, submitingTest, dataSubmit } = state.autotest
    return { getingDetail, detailSelfTest, submitingTest, dataSubmit }
}
const actionCreators = {
    getDetailTest: autotestActions.getDetailTest,
    submitAnswer: autotestActions.submitAnswer
};

const connectedClass = connect(mapState, actionCreators)(AnswerAutoTest);
export { connectedClass as AnswerAutoTest };