import React, { Component } from 'react'
import { connect } from 'react-redux'
import { authActions } from '../../../actions'
import { View, Text, StyleSheet } from 'react-native'
import { SubmitBtn, CheckBox, Loader } from '../_Common'
import { Colors } from '../../../config'
class UpdatePicture extends Component {
    constructor(props) {
        super(props)
        this.state = {
            picture: null,
            display_file:null,
            accept_condition: false
        }
        this.handleChoosePhoto = this.handleChoosePhoto.bind(this)
        this.cancleChosse = this.cancleChosse.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this._handleAccept = this._handleAccept.bind(this)
    }
    handleChoosePhoto = (e) => {
        if (e.target) {
            let fille = e.target.files[0]
            var reader = new FileReader();
            var srcfile = ''
            var self = this
            reader.onload = function (e) {
                srcfile = e.target.result
                self.setState({
                    picture: fille,
                    display_file: srcfile
                })
            };
            reader.readAsDataURL(fille);
        }
    }
    cancleChosse() {
        this.setState({
            picture: null,
            display_file: null
        })
    }
    handleSubmit() {
        let extension = 'png';
        if (this.state.picture.type.split('/') && this.state.picture.type.split('/')[1]) {
            extension = this.state.picture.type.split('/')[1]
        }
        var dataImage = new FormData()
        dataImage.append('condition', this.state.accept_condition)
        dataImage.append('file', this.state.picture)
        this.props.uploadPicture(dataImage)
    }
    _handleAccept(value) {
        this.setState({
            accept_condition: value
        })
    }
    render() {
        const { uploading, dataPicture } = this.props
        return (
            <View style={{ flex: 1, flexDirection: "column", justifyContent: "space-around", marginTop: 5 }}>
                {uploading === 0 && <Loader />}
                <View style={styles.flexRows}>
                    <CheckBox
                        actionPress={this._handleAccept} valueReturn={!this.state.accept_condition} checked={this.state.accept_condition}
                    />
                    <Text style={{ marginLeft: 10, width: "90%", fontWeight: "500", marginVertical: 10, fontSize: 18 }}>J’autorise L’Industreet à utiliser ma photo pour mon admission aux formations.</Text>
                </View>
                {this.state.display_file &&
                    <View style={{ flexDirection: "row", justifyContent: "center" }}>
                        <img src={this.state.display_file} style={{ width: 200, height: 200, borderRadius: 100 }} />
                    </View>
                }
                {this.state.picture ? <View style={{ flex: 1, flexDirection: "row", marginTop: 5, justifyContent: "center" }}>
                    <SubmitBtn
                        actionPress={this.cancleChosse}
                        text="Annuler"
                        fontClr={Colors.primary}
                        bdgClr="#2D70F320"
                        customStyle={{ paddingHorizontal: 40, marginVertical: 10, marginHorizontal: 10, paddingVertical: 13 }}
                    />
                    <SubmitBtn
                        actionPress={this.handleSubmit}
                        text="Envoyer"
                        fontClr="#fff"
                        bdgClr={Colors.primary}
                        customStyle={{ paddingHorizontal: 40, marginVertical: 10, marginHorizontal: 10, paddingVertical: 13 }}
                    />
                </View> :
                    <View>
                        <input type="file" style={{ display: 'none' }} onChange={this.handleChoosePhoto} id="chosse_file" />
                        <label htmlFor='chosse_file'>
                            <SubmitBtn
                                actionPress={() => this.handleChoosePhoto(0)}
                                text="Prendre photo"
                                fontClr="#fff"
                                bdgClr={Colors.primary}
                            />
                        </label>
                        <label htmlFor='chosse_file'>
                            <SubmitBtn
                                actionPress={() => this.handleChoosePhoto(1)}
                                text="Parcourir la galerie"
                                fontClr={Colors.primary}
                                bdgClr="#2D70F320"
                            />
                        </label>
                    </View>}
            </View>

        )
    }
}
const styles = StyleSheet.create({
    flexRows: {
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
    }
})
function mapState(state) {
    const { uploading, dataPicture } = state.auth
    return { uploading, dataPicture }
}
const actionsClass = {
    uploadPicture: authActions.uploadPicture
}
const connectedClass = connect(mapState, actionsClass)(UpdatePicture);
export { connectedClass as UpdatePicture };