import React, { Component } from 'react'
import { authActions } from '../actions'
import { connect } from 'react-redux'
import { View, ActivityIndicator, Text } from 'react-native'
import { LindustreetIcon } from './AppPages/_Common'
import { Colors } from '../config'
class LoadingApp extends Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        this.props.getUser()
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { getingUser, dataUser } = nextProps
        if (this.props.getingUser === 0 && getingUser === 1 && dataUser && dataUser.status === "done" && dataUser.user) {
            this.props.navigation.navigate("Home")
        }
        if (this.props.getingUser === 0 && getingUser === 2) {
            this.props.navigation.navigate("Login")
        }
    }
    render() {
        return (
            <View style={{ justifyContent: "center", alignItems: 'center', height: "100%", width: "100%", flexDirection: "column", backgroundColor: '#FFFFFF' }}>
                <LindustreetIcon color={Colors.primary} fillGlobal={Colors.primary} fillSub="#FFF" width={95} height={95} />
                <Text style={{ fontWeight: "700", fontSize: 42, marginVertical: 17, color: Colors.primary }}>L'industreet</Text>
                <ActivityIndicator size={40} color={Colors.primary} />
            </View>
        )
    }
}
function mapState(state) {
    const { getingUser, dataUser } = state.auth
    return { getingUser, dataUser }
}
const actionCreators = {
    getUser: authActions.getUser
};

const connectedClass = connect(mapState, actionCreators)(LoadingApp);
export { connectedClass as LoadingApp };