import { StyleSheet } from 'react-native'
export const styles = StyleSheet.create({
    container: {
        backgroundColor: "#FFFFFF",
        marginBottom: 15,
        width: "96%",
        marginLeft: "2%",
        padding: 15,
        shadowColor: "#000000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.2,
        shadowRadius: 4.65,
        elevation: 7,
        borderRadius: 8
    },
    spaceAround: {
        flexDirection: "row",
        justifyContent: "space-around"
    },
    alignFlex: {
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        alignSelf: "flex-start",
        marginBottom: 12
    },
    alignItem: {
        flexDirection: "row",
        alignItems: "center"
    },
    setSpaceBtwn: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    Title: {
        fontWeight: "700",
        fontSize: 20.5,
        color: "#252423",
        marginBottom: 15
    },
    titleOption: {
        fontWeight: "500",
        fontSize: 19,
        color: "#252423",
        marginLeft: 10
    },
    textArea: {
        borderColor: "#2D70F370",
        fontWeight: "500",
        fontSize: 18,
        height: 130,
        borderWidth: 2,
        textAlignVertical: 'top',
        padding: 10,
        borderRadius: 7
    }
});