import React, { Component } from 'react';
import { ScrollView, View, RefreshControl } from 'react-native'
import { connect } from 'react-redux'
import { autotestActions } from '../../../actions'
import { CostumTabBottum, CostumTopbar, PendingTest, Loader } from '../_Common'
import moment from "moment";
import { Colors } from '../../../config'
class AutoTestPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            attent: true,
            end: true,
            start: true
        }
        this._onRefresh = this._onRefresh.bind(this)
        props.getTest()
    }
    componentDidMount() {
    }
    _handlehidden(stateName) {
        let currentState = this.state[stateName]
        this.setState({
            [stateName]: !currentState
        })
    }
    _onRefresh() {
        this.props.getTest()
    }
    render() {
        const { getingTestSelf, dataTestSelf } = this.props
        var start = null
        var attent = null
        var end = null
        if (getingTestSelf === 1 && dataTestSelf && dataTestSelf.tests && !Array.isArray(dataTestSelf.tests)) {
            start = Array.isArray(dataTestSelf.tests.start) ? dataTestSelf.tests.start : null
            attent = Array.isArray(dataTestSelf.tests.attent) ? dataTestSelf.tests.attent : null
            end = Array.isArray(dataTestSelf.tests.end) ? dataTestSelf.tests.end : null
        }
        return (
            <>
                <CostumTopbar title="Là, c’est l’honnêteté !" />
                { getingTestSelf === 0 && <Loader />}
                <ScrollView
                    showsVerticalScrollIndicator={false}
                >

                    {start && <View style={this.state.start ? {} : { height: 0, overflow: "hidden" }}>
                        {start.map((test, key) => {
                            return <PendingTest key={key} typeTest="start" navigation={this.props.navigation} path="AnswerAutoTest" idTest={test.test_auto_id} time="" date={moment(new Date(test.dateautotest.split(' ')[0])).startOf('day').fromNow()} type="1" navigation={this.props.navigation} />
                        })}
                    </View>}
                    {attent && <View style={this.state.attent ? {} : { height: 0, overflow: "hidden" }}>
                        {attent.map((test, key) => {
                            return <PendingTest key={key} typeTest="attent" navigation={this.props.navigation} path="AnswerAutoTest" idTest={test.test_auto_id} time="" date={moment(new Date(test.dateautotest.split(' ')[0])).startOf('day').fromNow()} type="1" navigation={this.props.navigation} />
                        })}
                    </View>}
                    {end && <View style={this.state.end ? {} : { height: 0, overflow: "hidden" }}>
                        {end.map((test, key) => {
                            return <PendingTest key={key} typeTest="end" navigation={this.props.navigation} path="DetailAutoTest" idTest={test.test_auto_id} time="" date={moment(new Date(test.dateautotest.split(' ')[0])).format('Do MMMM YYYY')} type="1" navigation={this.props.navigation} />
                        })}
                    </View>}
                </ScrollView>
                <CostumTabBottum />
            </>
        )
    }
}
function mapState(state) {
    const { getingTestSelf, dataTestSelf } = state.autotest
    return { getingTestSelf, dataTestSelf }
}
const actionCreators = {
    getTest: autotestActions.getTest
};

const connectedClass = connect(mapState, actionCreators)(AutoTestPage);
export { connectedClass as AutoTestPage };