import React from 'react'
import { HomeSvg, EvaluSvg, AutoSvg, MoreSvg } from './IconsSvg'
import { View, StyleSheet, TouchableOpacity, Text } from 'react-native'
import { useHistory,useLocation } from "react-router-dom";
import { Colors } from '../../../config'
const styles = StyleSheet.create({
    container: {
        width: "100%",
        height: 62,
        backgroundColor: "#ffffff",
        shadowColor: "#000000",
        shadowOffset: {
            width: 5,
            height: 20,
        },
        shadowOpacity: 0.7,
        shadowRadius: 10,
        elevation: 10,
    },
    flexView: {
        height: "100%",
        flexDirection: 'row',
        justifyContent: "space-around",
        alignItems: 'center',
    },
    elementBar: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
    },
    textAction: {
        fontWeight: "700"
    }
});
const CostumTabBottum = (props) => {
    const navigation = useHistory();
    const route = useLocation()
    return (
        <View style={styles.container}>
            <View style={styles.flexView}>
                <TouchableOpacity onPress={() => navigation.push('/Home')}>
                    <View style={styles.elementBar}>
                        <HomeSvg color={route.pathname === "/Home" ? Colors.primary : Colors.iconColor} />
                        <Text style={[styles.textAction, { color: route.pathname === "/Home" ? Colors.primary : Colors.iconColor }]}>Accueil</Text>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => navigation.push('/EvaluTest')}>
                    <View style={styles.elementBar}>
                        <EvaluSvg color={route.pathname === "/EvaluTest" ? Colors.primary : Colors.iconColor} />
                        <Text style={[styles.textAction, { color: route.pathname === "/EvaluTest" ? Colors.primary : Colors.iconColor, marginTop: 6 }]}>Tests</Text>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => navigation.push('/AutoTest')}>
                    <View style={styles.elementBar}>
                        <AutoSvg color={route.pathname === "/AutoTest" ? Colors.primary : Colors.iconColor} />
                        <Text style={[styles.textAction, { color: route.pathname === "/AutoTest" ? Colors.primary : Colors.iconColor, marginTop: 3 }]}>Honnêteté</Text>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => navigation.push('/More')}>
                    <View style={styles.elementBar}>
                        <MoreSvg color={route.pathname === "/More" ? Colors.primary : Colors.iconColor} />
                        <Text style={[styles.textAction, { color: route.pathname === "/More" ? Colors.primary : Colors.iconColor }]}>Plus</Text>
                    </View>
                </TouchableOpacity>
            </View>
        </View>
    )
}
export { CostumTabBottum }