import {userConstants} from '../constants'
export function auth(state , action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                loging:0,
                dataLogin:action.payload
            }
        case userConstants.LOGIN_SUCCESS:
            return {
                loging:1,
                dataLogin:action.payload
            }
        case userConstants.LOGIN_FAILURE:
            return {
                loging:2,
                dataLogin:action.payload
            }
        case userConstants.UPDATE_PASSWORD_REQUEST:
            return {
                ...state,
                updatpass:0,
                dataPass:action.payload
            }
        case userConstants.UPDATE_PASSWORD_SUCCESS:
            return {
                ...state,
                updatpass:1,
                dataPass:action.payload
            }
        case userConstants.UPDATE_PASSWORD_FAILURE:
            return {
                ...state,
                updatpass:2,
                dataPass:action.payload
            }
        case userConstants.UPLOADING_PIC_REQUEST:
            return {
                ...state,
                uploading:0,
                dataPicture:action.payload
            }
        case userConstants.UPLOADING_PIC_SUCCESS:
            let toreturn={
                dataPicture:action.payload
            }
            if(action.payload && action.payload.status==="done"){
                toreturn={
                    dataUser:{
                        user:{
                            ...state.dataUser.user,
                            candidate:{
                                ...state.dataUser.user.candidate,
                                accept_condition:true
                            },
                            picture:action.payload.message
                        }
                        
                    }
                }
            }
            return {
                ...state,
                uploading:1,
                ...toreturn
            }
        case userConstants.UPLOADING_PIC_FAILURE:
            return {
                ...state,
                uploading:2,
                dataPicture:action.payload
            }
        case userConstants.GET_USER_REQUEST:
            return {
                ...state,
                getingUser:0,
                dataUser:action.payload
            }
        case userConstants.GET_USER_SUCCESS:
            return {
                ...state,
                getingUser:1,
                dataUser:action.payload
            }
        case userConstants.GET_USER_FAILURE:
            return {
                ...state,
                getingUser:2,
                dataUser:action.payload
            }
        case userConstants.GET_TOKEN_PUSH_REQUEST:
            return {
                ...state,
                getingToken:0,
                dataTokenPush:action.payload
            }
        case userConstants.GET_TOKEN_PUSH_SUCCESS:
            return {
                ...state,
                getingToken:1,
                dataTokenPush:action.payload
            }
        case userConstants.GET_TOKEN_PUSH_FAILURE:
            return {
                ...state,
                getingToken:2,
                dataTokenPush:action.payload
            }
        default:
            return {...state}
        }
}