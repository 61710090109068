import React, { Component } from 'react';
import { CostumTabBottum, ArrowAcesSvg, CostumTopbar } from '../_Common'
import { homeActions } from '../../../actions'
import { connect } from 'react-redux'
import { Text, View, ScrollView, StyleSheet, TouchableOpacity, Image } from 'react-native'
import { UpdatePicture } from './UpdatePicture'
import { Colors } from '../../../config'
class HomePage extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        const { dataUser } = this.props
        return (
            <>
                <CostumTopbar title="Bienvenue à la Journée Test d’Affinité" />
                <ScrollView showsVerticalScrollIndicator={false}>
                    <View style={Styles.container}>
                        {dataUser.user.picture ?
                            <View style={{ flexDirection: "column", alignItems: "center" }}>
                                <Text style={[{ color: Colors.primary }, Styles.titleText]}>Salutation, {dataUser.user.firstname}</Text>
                                <Text style={{ fontWeight: "500", fontSize: 16, textAlign: "center", marginTop: 10 }}>C’est ici que se passeront les tests de la Journée Test d’Affinité ! Bonne chance !</Text>
                                <Image
                                    source={{ uri: dataUser.user.picture }}
                                    style={{ width: 110, height: 110, borderRadius: 100, marginVertical: 10 }}
                                />
                            </View> :
                            <View>
                                <Text style={[{ color: Colors.primary }, Styles.titleText]}>Salutation, {dataUser.user.firstname}</Text>
                                <Text style={Styles.subText}>Pensez à mettre une photo de vous et à autoriser le droit à l’image. Cette image sera utilisée uniquement par L’Industreet et aidera l’évaluateur à vous guider.</Text>
                                <UpdatePicture />
                            </View>}
                    </View>
                    <View style={[Styles.container, { marginVertical: 5 }]}>
                        <Text style={Styles.titleText}>Les grilles</Text>
                        <TouchableOpacity onPress={() => this.props.navigation.navigate('EvaluTest')}>
                            <View style={Styles.btnGo}>
                                <Text style={{ fontWeight: "500", fontSize: 20 }}>Là, c’est le test !</Text>
                                <ArrowAcesSvg width="24" height="24" />
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => this.props.navigation.navigate('AutoTest')}>
                            <View style={Styles.btnGo}>
                                <Text style={{ fontWeight: "500", fontSize: 20 }}>Là, c’est l’honnêteté !</Text>
                                <ArrowAcesSvg width="24" height="24" />
                            </View>
                        </TouchableOpacity>
                    </View>
                </ScrollView>
                <CostumTabBottum />
            </>
        )
    }
}
const Styles = StyleSheet.create({
    container: {
        backgroundColor: "#FFFFFF",
        paddingHorizontal: 12,
        paddingVertical: 15,
        marginVertical: 15,
        marginHorizontal: 15,
        borderRadius: 10,
        shadowColor: "#000000",
        shadowOffset: {
            width: 2,
            height: 2,
        },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 5,
    },
    titleText: {
        fontSize: 25,
        fontWeight: "700"
    },
    subText: {
        fontSize: 18,
        marginTop: 15
    },
    btnGo: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#2D70F320",
        paddingVertical: 15,
        paddingHorizontal: 20,
        borderRadius: 10,
        marginVertical: 10
    }
})
function mapState(state) {
    const { dataUser } = state.auth
    const { getingHome, firstTest, otherTest } = state.home
    return { dataUser, getingHome, firstTest, otherTest }
}
const actionCreators = {
    getHomeTest: homeActions.getHomeTest
};

const connectedClass = connect(mapState, actionCreators)(HomePage);
export { connectedClass as HomePage };