import React from 'react'
import { Text, View } from 'react-native'
import { styles } from '.'
import { Colors } from '../../../../config'
export const MessageItem = ({ isMe, message }) => {
    return (
        <View style={[styles.message, isMe ? { flexDirection: 'row-reverse' } : {}]}>
            <View style={[styles.messageContent, { backgroundColor: !isMe ? Colors.white : Colors.primaryOpcaity, }]}>
                <Text style={[styles.textStyle]}>{message}</Text>
            </View>
        </View>
    )
}