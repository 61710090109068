import React from 'react'
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native'
import { RightArrow, EvaluSvg, AutoSvg } from './IconsSvg'
import { Colors } from '../../../config'
export function NextEvaluation(props) {
    const types = {
        attent: {
            color: Colors.pending,
            bdgColor: Colors.pendingOpcaity,
            textBar: "En attent"
        },
        end: {
            color: Colors.succes,
            bdgColor: Colors.succesOpacity,
            textBar: "Terminé"
        },
        start: {
            color: Colors.primary,
            bdgColor: "#2D70F340",
            textBar: "En cours"
        }
    }
    const styles = StyleSheet.create({
        container: {
            flex: 1,
            flexDirection: "column",
            width: "95%",
            backgroundColor: "#FFFFFF",
            marginStart: "auto",
            marginEnd: "auto",
            borderRadius: 20,
            paddingHorizontal: 17,
            paddingVertical: 15,
        },
        firstTab: {
            flex: 1,
            flexDirection: 'row',
            justifyContent: "space-between",
            alignItems: "flex-start"
        },
        labelType: {
            paddingHorizontal: 13,
            paddingVertical: 5,
            backgroundColor: types[props.typeTest].bdgColor,
            borderRadius: 14
        },
        topText: {
            fontSize: 25,
            fontWeight: "700",
            textTransform: "capitalize"
        },
        smallText: {
            fontSize: 13,
            fontFamily: "Roboto-Regular",
            color: "#707070"
        },
        secendTab: {
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 30
        },
        alignItm: {
            flex: 1,
            flexDirection: "row",
            alignItems: "center"
        }
    })
    return (
        <TouchableOpacity onPress={() => props.navigation.navigate(props.type === "1" ? 'AnswerEvaluTest' : "AnswerAutoTest", { idtest: props.idTest })}>
            <View style={styles.container}>
                <View style={styles.firstTab}>
                    <View>
                        <Text style={styles.topText}>{props.date}</Text>
                        <Text style={styles.smallText}>{props.time}</Text>
                    </View>
                    <View style={styles.labelType}>
                        <Text style={{ color: types[props.typeTest].color }}>{types[props.typeTest].textBar}</Text>
                    </View>
                </View>
                <View style={styles.secendTab}>
                    <View style={styles.alignItm}>
                        {props.type === "1" ? <EvaluSvg color="#70707070" /> : <AutoSvg color="#70707070" />}
                        <Text style={{ fontFamily: "Roboto-Regular", fontSize: 15, marginLeft: 5 }}>{props.type === "1" ? "Test de conaissance" : "Test d'auto-évaluation"}</Text>
                    </View>
                    <RightArrow />
                </View>
            </View>
        </TouchableOpacity>
    )
}