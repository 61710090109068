import React, { Component } from 'react';
import { Text, ScrollView, View } from 'react-native'
import { answerEvaluActions, testevalActions } from '../../../actions'
import { connect } from 'react-redux'
import { Loader, QestionElment, SubmitBtn, CheckElement, CheckIcon, WaitingSvg ,CostumTopbar} from '../_Common'
import { Colors } from '../../../config'
class AnswerEvaluTest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: 1,
            nbPage: 10
        }
        this.answers = null
        this.qestions = []
        this.scrollBack = React.createRef()
        this._handleNext = this._handleNext.bind(this)
        this._handleSubmit = this._handleSubmit.bind(this)
        this._handleAnswers = this._handleAnswers.bind(this)
        props.getDetailTest(props.match.params.idtest)
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { getingDetailEval, dataDetailEval, initAnswer } = nextProps
        if (this.props.getingDetailEval === 0 && getingDetailEval === 1 && dataDetailEval && Array.isArray(dataDetailEval.qestions)) {
            this.answers = dataDetailEval.qestions
            this.qestions = dataDetailEval.qestions
        }
    }
    componentDidMount() {

    }
    _handleAnswers(value) {
        this.answers[value.index] = value.qst
    }
    _handleNext(value) {
        this.scrollBack.scrollTo({ x: 0, y: 0 })
        let index = this.state.show
        this.setState({
            show: (index + value)
        })
        this.scrollBack.scrollTo({ x: 0, y: 0 })
    }
    _handleSubmit() {
        let answers = { test_connaissance_id: this.props.dataDetailEval.test_connaissance_id, questions: this.answers }
        this.props.submitAnswer(answers)
    }
    render() {
        const { getingDetailEval, submitinAnswer, dataDetailEval } = this.props
        var qestion = []
        var end = 0
        var start = 0
        if (this.qestions) {
            start = (this.state.show * this.state.nbPage) - this.state.nbPage
            end = this.state.show * this.state.nbPage
            qestion = this.qestions.slice(start, end).map((qst, key) => {
                return <QestionElment key={key} index={key + start} qst={qst} handleAnswer={this._handleAnswers} />
            })
        }
        return (
            <>
            <CostumTopbar title="Test de connaissance" goBack={this.props.history.goBack} />
            <View style={{ flex: 1, height: "100%" }}>{getingDetailEval === 0 ? <Loader /> : <>
                {submitinAnswer === 0 && <Loader />}
                {dataDetailEval.message === "Test not started" ?
                    <CheckElement Icon={<WaitingSvg width="110" height="110" />}>
                        <Text style={{ fontSize: 20, fontWeight: "700", textAlign: "center" }}>Vous recevrez une notification de la part de L’Industreet lorsque le test commencera ☺️</Text>
                    </CheckElement> : dataDetailEval.message ?
                        <CheckElement Icon={<CheckIcon width="110" height="110" />}>
                            <Text style={{ fontSize: 20, fontWeight: "700", textAlign: "center" }}>Votre test de connaissances est terminé ! Bravo ! ☺️</Text>
                        </CheckElement> :

                        <ScrollView showsVerticalScrollIndicator={false} ref={ref => (this.scrollBack = ref)}>
                            <>
                                <View style={{ paddingTop: 12 }}></View>
                                {qestion}
                                {this.qestions.length > end ?
                                    <View style={{ flexDirection: "row", justifyContent: "space-around" }}>
                                        {start > 0 && <SubmitBtn actionPress={() => this._handleNext(-1)} text="Precedent" bdgClr="#2D70F320" fontClr={Colors.primary} />}
                                        <SubmitBtn actionPress={() => this._handleNext(1)} text="Suivant" bdgClr={Colors.primary} fontClr="#FFF" />
                                    </View> :
                                    <View style={{ flexDirection: "row", justifyContent: "space-around" }}>
                                        {start > 0 && <SubmitBtn actionPress={() => this._handleNext(-1)} text="Retour" bdgClr="#2D70F320" fontClr={Colors.primary} />}
                                        <SubmitBtn actionPress={this._handleSubmit} text="Envoyer" bdgClr={Colors.primary} fontClr="#FFF" />
                                    </View>}</>
                        </ScrollView>}</>}
            </View>
            </>
        )
    }
}
function mapState(state) {
    const { getingDetailEval, dataDetailEval, submitinAnswer, dataSubmit } = state.testeval
    return { getingDetailEval, dataDetailEval, submitinAnswer, dataSubmit }
}
const actionCreators = {
    initAnswer: answerEvaluActions.initAnswer,
    getDetailTest: testevalActions.getDetailTest,
    submitAnswer: testevalActions.submitAnswer
};

const connectedClass = connect(mapState, actionCreators)(AnswerEvaluTest);
export { connectedClass as AnswerEvaluTest };